import React from 'react';
import { GALLERY_CONSTS } from 'pro-gallery';
import { utils } from '../../utils/webUtils';
import { experimentsWrapper } from '@wix/photography-client-lib';
import {
  EXTERNAL_INFO_TYPE,
  TextInfoElement,
} from '@wix/pro-gallery-info-element';

export default class ItemsHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;
    this.update = this.update.bind(this);
    this.pgItemsProps = this.pgItemsProps.bind(this);
    this.areOneOrMoreItemsCorrupted = this.areOneOrMoreItemsCorrupted.bind(
      this,
    );
    this.getMoreItems = this.getMoreItems.bind(this);
    this.processPgItemPropsForInfoElement = this.processPgItemPropsForInfoElement.bind(
      this,
    );
    this.getExternalInfoRenderers = this.getExternalInfoRenderers.bind(this);
    this.getMoreItemsCalled = false;
  }

  isFreeArtStore() {
    const { additionalProviderParams } = this.galleryWrapperProps;
    return (
      additionalProviderParams && additionalProviderParams.freeArtStore === true
    );
  }

  getCustomDownloadUrlFuncIfNeeded() {
    if (this.isStoreGallery && this.isFreeArtStore()) {
      return async (mediaUrl, itemId) => {
        const {
          galleryId,
          additionalProviderParams: { srcId },
        } = this.galleryWrapperProps;
        const url = `https://www.wix.com/_api/albums-node-server/getSecuredFileUrl?galleryId=${galleryId}&instanceId=${srcId}&itemId=${itemId}&mediaUrl=${mediaUrl}`;
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        return data[0].path;
      };
    } else {
      return undefined;
    }
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  pgItemsProps(pgStyles) {
    let items;

    if (!this.galleryWrapperProps.wixCodeItems) {
      items =
        this.galleryWrapper.state.manualItems ||
        this.galleryWrapperProps.items ||
        [];
    } else {
      items = this.galleryWrapperProps.wixCodeItems.map((item) => {
        if (typeof item.metaData === 'string') {
          try {
            const newItem = {
              ...item,
              metaData: JSON.parse(item.metaData),
            };
            return newItem;
          } catch (e) {
            console.error('Failed parse item metaData', e);
          }
        }
        return item;
      });
    }

    const totalItemsCount =
      this.galleryWrapperProps.totalItemsCount || items.length || 1;

    return {
      items,
      totalItemsCount,
    };
  }

  areOneOrMoreItemsCorrupted(items) {
    return items.some(this.isInvalidItem);
  }

  isInvalidItem(item) {
    // for future validations add more conditions
    const containsItemId = item.itemId === undefined;
    return containsItemId;
  }

  getMoreItems(from) {
    if (this.galleryWrapperProps.getMoreItems) {
      this.getMoreItemsCalled = true;
      this.galleryWrapperProps.getMoreItems(from);
    }
  }

  processPgItemPropsForInfoElement(infoType, pgItemProps) {
    const wrapperProps = {
      viewMode: this.galleryWrapper.siteHelper.parseViewMode(
        this.galleryWrapperProps.viewMode,
      ),
      eventsListener: this.galleryWrapper.syncEventHandler.eventHandler,
      infoType,
    };

    const itemLoveData = {
      ...this.galleryWrapper.state.itemsLoveData[pgItemProps.id],
    };

    return {
      ...pgItemProps,
      ...wrapperProps,
      ...itemLoveData,
      customDownloadUrl: this.getCustomDownloadUrlFuncIfNeeded(),
    };
  }

  shouldTextBeOnRequestedInfoType(
    requestedInfoType,
    titlePlacement,
    isSlideshow,
  ) {
    switch (requestedInfoType) {
      case EXTERNAL_INFO_TYPE.HOVER:
        return GALLERY_CONSTS.hasHoverPlacement(titlePlacement);
      case EXTERNAL_INFO_TYPE.SLIDESHOW:
        return isSlideshow;
      case EXTERNAL_INFO_TYPE.EXTERNAL:
        return (
          GALLERY_CONSTS.hasVerticalPlacement(titlePlacement) ||
          GALLERY_CONSTS.hasHorizontalPlacement(titlePlacement)
        );
      default:
        return true;
    }
  }

  fetchInfoElementIfNeeded() {
    if (utils.isSSR()) {
      return;
    }
    if (!this.infoElement && !this.fetchingInfoElement) {
      this.fetchingInfoElement = true;
      import(
        /* webpackChunkName: "ProGalleryInfoElement" */ '@wix/pro-gallery-info-element'
      ).then((module) => {
        this.fetchingInfoElement = false;
        const { InfoElement } = module;
        this.infoElement = InfoElement;
      });
    }
  }

  initItemActionsIfNeeded(pgItemProps) {
    try {
      if (pgItemProps.styleParams.loveButton) {
        this.galleryWrapper.itemActionsHelper.initItemActions();
      }
    } catch (ex) {}
  }

  renderInfoElement(type, pgItemProps) {
    const InfoElement =
      this.infoElement ||
      (this.shouldTextBeOnRequestedInfoType(
        type,
        pgItemProps.styleParams.titlePlacement,
        pgItemProps.styleParams.isSlideshow,
      )
        ? TextInfoElement
        : null);
    return (
      InfoElement && (
        <InfoElement
          {...this.processPgItemPropsForInfoElement(type, pgItemProps)}
        />
      )
    );
  }

  fetchItemMetadata(pgItemProps) {
    this.fetchInfoElementIfNeeded();
    this.initItemActionsIfNeeded(pgItemProps);
  }

  hoverInfoElement = (pgItemProps) => {
    this.fetchItemMetadata(pgItemProps);
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.HOVER, pgItemProps);
  };

  externalInfoElement = (pgItemProps) => {
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.EXTERNAL, pgItemProps);
  };

  slideshowInfoElement = (pgItemProps) => {
    this.fetchItemMetadata(pgItemProps);
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.SLIDESHOW, pgItemProps);
  };

  getExternalInfoRenderers() {
    return {
      customHoverRenderer: this.hoverInfoElement,
      customInfoRenderer: this.externalInfoElement,
      customSlideshowInfoRenderer: this.slideshowInfoElement,
    };
  }
}
